
.ErrorTemplate { padding: 200px 25px 0px 350px; text-align: center;  color: white;}

.ErrorActions {margin-top:15px;margin-bottom:15px;
}

.ErrorActions .btn { margin-right:10px;  background: var(--colorred);
    box-shadow: 0px 10px 30px rgba(193, 42, 55, 0.24);
    border-radius: 5px;
    border: 1px solid var(--colorred);
    font-size: 14px;
    font-family: var(--fontbold);
    color: var(--colorwhite);}