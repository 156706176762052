@font-face {
  font-family: Poppins-Bold;
  src: url("../fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url("../fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: Poppins-Regular;
  src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: Space-Age;
  src: url("../fonts/Space-Age.ttf");
}

::-webkit-autofill {
  transition-delay: 9999s;
}
